import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import {
  modal,
  modalBackground,
  modalContent,
} from '../../styles/components/elements/modal.css';
import { isClipped } from '../../styles/components/utils/overflow.css';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  container?: HTMLElement;
  active?: boolean;
}

export default function Modal({
  container = document.documentElement,
  active,
  className,
  children,
  ...props
}: Props) {
  const focusModal = useCallback((element: HTMLElement) => {
    element.focus();
  }, []);

  useEffect(() => {
    if (active) {
      container.classList.add(isClipped);
    } else {
      container.classList.remove(isClipped);
    }

    return () => container.classList.remove(isClipped);
  }, [container, active]);

  return (
    <div
      role="dialog"
      aria-hidden={!active}
      tabIndex={-1}
      ref={focusModal}
      className={modal({ active })}
    >
      <div className={modalBackground}></div>
      <div
        {...props}
        role="document"
        className={classNames(className, modalContent)}
      >
        {children}
      </div>
    </div>
  );
}
