import React from 'react';
import classNames from 'classnames';
import { heading } from '../../styles/components/elements/heading.css';

export interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  size?: 1 | 2 | 3 | 4 | 5 | 6;
}

export default function Heading({
  size = 3,
  className,
  children,
  ...props
}: Props) {
  const HeadingTag = `h${size}` as const;

  return (
    <HeadingTag {...props} className={classNames(className, heading[size])}>
      {children}
    </HeadingTag>
  );
}
