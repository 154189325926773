import classNames from 'classnames';
import React from 'react';
import { box } from '../../styles/components/elements/box.css';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export default function Box({ className, children, ...props }: Props) {
  return (
    <div {...props} className={classNames(className, box)}>
      {children}
    </div>
  );
}
