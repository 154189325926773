import React from 'react';
import classNames from 'classnames';
import {
  button,
  ButtonVariants,
} from '../../styles/components/elements/button.css';

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonVariants;

export default React.forwardRef<HTMLInputElement, Props>(function Button(
  { className, color, fullwidth, loading, children, ...props },
  ref
) {
  return (
    <button
      {...props}
      ref={ref}
      className={classNames(className, button({ color, fullwidth, loading }))}
    >
      {children}
    </button>
  );
});
