import { Link } from 'gatsby';
import React, { useState } from 'react';
import Box from './elements/box';
import Button from './elements/button';
import Heading from './elements/heading';
import Modal from './elements/modal';
import Checkbox from './form/checkbox';
import Field from './form/field';
import { buttonGroup } from '../styles/components/elements/button-group.css';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

interface CookieConsentResponse {
  success: boolean;
  result: CookieConsentResult;
}

interface CookieConsentResult {
  expires: string;
  subjects: string[];
  uuid: string;
}

export interface CookieConsentContext {
  subjects: string[];
  showModal: () => void;
}

export const CookieConsentContext = React.createContext<CookieConsentContext>({
  subjects: [],
  showModal: () => {},
});

function useCookieConsent(): CookieConsentResult | undefined {
  try {
    const item = localStorage.getItem('cookieConsent');
    if (!item) return;

    const json: CookieConsentResult = JSON.parse(item);
    if (Date.now() > new Date(json.expires).getTime()) {
      return;
    }

    return json;
  } catch {}
}

export default function CookieConsent({ children }: Props) {
  const givenConsent = useCookieConsent();
  const [active, setActive] = useState(!givenConsent);
  const [customize, setCustomize] = useState(false);
  const [subjects, setSubjects] = useState<string[]>(
    givenConsent?.subjects || []
  );
  const [ctxSubjects, setCtxSubjects] = useState<string[]>(
    givenConsent?.subjects || []
  );

  async function submitConsent(customSubjects?: string[]) {
    try {
      const newSubjects = customSubjects || subjects;
      setSubjects(newSubjects);
      setCtxSubjects(newSubjects);
      setActive(false);

      const res = await fetch('/api/cookieConsent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
        body: JSON.stringify({
          subjects: newSubjects.filter((x) => x !== 'REQUIRED'),
          uuid: givenConsent?.uuid,
        }),
      });

      if (!res.ok) {
        throw new Error(`${res.status}: ${res.statusText}`);
      }

      const { result }: CookieConsentResponse = await res.json();
      localStorage.setItem('cookieConsent', JSON.stringify(result));
    } catch (err) {
      console.error(err);
    }
  }

  function onCheckboxChange({
    target: { checked, name },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (!checked) {
      setSubjects(subjects.filter((x) => x !== name));
    } else if (!subjects.includes(name)) {
      setSubjects([...subjects, name]);
    }
  }

  return (
    <CookieConsentContext.Provider
      value={{
        subjects: ctxSubjects,
        showModal: () => {
          setCustomize(true);
          setActive(true);
        },
      }}
    >
      {children}
      <Modal active={active}>
        <Box>
          <Heading size={4}>Cookies 🍪</Heading>
          <p style={{ lineHeight: 1.5, margin: '1.5rem 0' }}>
            Deze website gebruikt cookies om uw ervaring op onze website te
            verbeteren en voor marketingdoeleinden, sociale media en het
            bijhouden van statistieken. Door op 'Zelf instellen' te klikken
            krijg je de mogelijkheid om je voorkeuren aan te passen. Door op
            'Alles accepteren' te klikken ga je akkoord met het gebruik van
            cookies zoals beschreven in ons{' '}
            <Link to="/privacybeleid">Privacybeleid</Link>.
          </p>
          {customize && (
            <div
              style={{
                marginBottom: '2em',
                marginLeft: '2em',
                marginRight: '2em',
              }}
            >
              <p style={{ marginBottom: '0.5em', fontWeight: 700 }}>
                Pas uw voorkeuren aan
              </p>
              <form>
                <Field>
                  <Checkbox name="required" checked disabled>
                    Noodzakelijk
                  </Checkbox>
                </Field>

                <Field>
                  <Checkbox
                    name="ANALYTICS"
                    onChange={onCheckboxChange}
                    checked={subjects.includes('ANALYTICS')}
                  >
                    Analytisch
                  </Checkbox>
                </Field>

                <Field>
                  <Checkbox
                    name="MARKETING"
                    onChange={onCheckboxChange}
                    checked={subjects.includes('MARKETING')}
                  >
                    Marketing
                  </Checkbox>
                </Field>
              </form>
            </div>
          )}

          <div className={buttonGroup}>
            {customize && (
              <Button
                tabIndex={0}
                color="text"
                fullwidth
                onClick={() => submitConsent()}
              >
                Voorkeuren opslaan
              </Button>
            )}
            {!customize && (
              <Button
                tabIndex={0}
                color="text"
                fullwidth
                onClick={() => {
                  setCustomize(true);
                }}
              >
                Zelf instellen
              </Button>
            )}

            <Button
              tabIndex={0}
              fullwidth
              onClick={() => {
                submitConsent(['ANALYTICS', 'MARKETING']);
              }}
            >
              Alles accepteren
            </Button>
          </div>
        </Box>
      </Modal>
    </CookieConsentContext.Provider>
  );
}
