import React from 'react';
import { WrapPageElementNodeArgs } from 'gatsby';
import CookieConsent from './src/components/cookie-consent';
import type { PageData } from './src/components/page';

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementNodeArgs<PageData>) => {
  return <CookieConsent>{element}</CookieConsent>;
};
