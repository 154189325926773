import classNames from 'classnames';
import React from 'react';
import * as styles from '../../styles/components/form/Field.module.css';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  grouped?: boolean;
}

export default function Field({ className, grouped, children }: Props) {
  return (
    <div className={classNames(className, styles.field, {
      [styles.isGrouped]: grouped,
    })}>
      {children}
    </div>
  );
}
