import classNames from 'classnames';
import React from 'react';
import {
  checkbox,
  checkboxInput,
} from '../../styles/components/form/checkbox.css';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

export default function Checkbox({ className, children, ...props }: Props) {
  return (
    <label
      className={checkbox}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      {children}
      <input
        type="checkbox"
        {...props}
        className={classNames(className, checkboxInput)}
      />
    </label>
  );
}
